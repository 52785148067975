import {useGlobalState} from "../../state/GlobalState";
import {RangeOption} from "../../types/filter-option";
import React, {FocusEvent, useEffect, useState} from "react";
import {ConfigurationGridData} from "../../types/ConfigurationGridData";
import Slider from "@mui/material/Slider";
import styles from "../../styles/RangeFilter.module.scss";
import {Box} from "@mui/material";

export default function RangeFilter(props: {
  configurationGridData?: ConfigurationGridData[];
  setShowClearFilterButton: Function;
  showClearFilter: boolean;
}) {
  const [, setRangeFilters] = useGlobalState("filterRangeOptions");
  const [vehicleLine] = useGlobalState("vehicleLine");
  const [isOpen, setIsOpen] = useState(false);
  const [minRange, setMinRange] = useState(0);
  const [maxRange, setMaxRange] = useState(0);
  const [selectedMax, setSelectedMax] = useState(0);
  const [selectedMin, setSelectedMin] = useState(0);
  const [msrpFilterText, setMsrpFilterText] = useState("MSRP");
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const sliderMinDistance = 10;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: FocusEvent) => {
    if (!(event.currentTarget as Node).contains(event.relatedTarget as Node)) {
      setIsOpen(false);
    }
  };

  const msrpFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  useEffect(() => {
    if (!props.showClearFilter) {
      setSelectedMin(minRange);
      setSelectedMax(maxRange);
    }
  }, [props.showClearFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateRangeFilterInputBoxes();
  }, [selectedMin, selectedMax]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedMax !== maxRange || selectedMin !== minRange) {
      setMsrpFilterText(
        msrpFormatter.format(selectedMin) +
          " - " +
          msrpFormatter.format(selectedMax)
      );
      props.setShowClearFilterButton(true);
    } else {
      setMsrpFilterText("MSRP");
      props.setShowClearFilterButton(false);
    }
  }, [selectedMin, selectedMax]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setSelectedMin(Math.min(newValue[0], selectedMax - sliderMinDistance));
    } else {
      setSelectedMax(Math.max(newValue[1], selectedMin + sliderMinDistance));
    }
  };

  function getMinAndMaxMSRP() {
    const msrpList = props.configurationGridData
      ? props.configurationGridData
          ?.filter((config) => config.vehicleLine === vehicleLine)
          .map((e) => e.msrpWithDetails.totalMSRPWithDestinationDelivery)
      : [];
    const minMsrp = Math.min.apply(null, msrpList);
    const maxMsrp = Math.max.apply(null, msrpList);

    setMinRange(minMsrp);
    setMaxRange(maxMsrp);
    setSelectedMax(maxMsrp);
    setSelectedMin(minMsrp);
  }

  useEffect(() => {
    const checkDataAvailability = () => {
      if (
        (props.configurationGridData?.length ?? 0) > 0 &&
        vehicleLine !== "" &&
        vehicleLine !== null
      ) {
        setIsDataAvailable(true);
        getMinAndMaxMSRP();
      } else {
        setIsDataAvailable(false);
      }
    };

    checkDataAvailability();
  }, [vehicleLine, props.configurationGridData]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateRangeFilterInputBoxes = () => {
    setRangeFilters((previousState: Map<string, RangeOption>) => {
      const newMap = new Map(previousState);
      newMap.set("msrp", {value: [selectedMin, selectedMax], label: "msrp"});
      return newMap;
    });
  };

  const handleMinInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value.replace(/[^0-9.-]+/g, ""));
    if (!isNaN(value)) setSelectedMin(value);
  };

  const handleMaxInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value.replace(/[^0-9.-]+/g, ""));
    if (!isNaN(value)) setSelectedMax(value);
  };

  return (
    <div className={styles.rangeDropdown} onBlur={(e) => handleClickOutside(e)}>
      <div
        className={
          isDataAvailable
            ? styles.msrpButtonContainer
            : styles.msrpButtonContainerDisabled
        }>
        <button
          data-testid={"msrp-filter"}
          className={
            msrpFilterText === "MSRP"
              ? styles.msrpButton
              : styles.msrpButtonPressed
          }
          onClick={handleClick}
          disabled={!isDataAvailable}>
          {msrpFilterText}
        </button>
      </div>
      {isOpen && (
        <div className={styles.rangeContainer} id={"range-container"}>
          <div className={styles.inputRow}>
            <div className={styles.rangeInputContainer}>
              <label className={styles.inputLabel} htmlFor="minInput">
                Min
              </label>
              <input
                name={"minInput"}
                className={"fmc-input"}
                value={msrpFormatter.format(selectedMin)}
                data-testid={"min-range-filter"}
                onChange={handleMinInputChange}
                onBlur={(e) => {
                  setSelectedMin(
                    Math.max(
                      Number(e.target.value.replace(/[^0-9.-]+/g, "")),
                      minRange
                    )
                  );
                }}
              />
            </div>
            <div className={styles.inputDivider}>-</div>
            <div className={styles.rangeInputContainer}>
              <label className={styles.inputLabel} htmlFor="maxInput">
                Max
              </label>
              <input
                name={"maxInput"}
                className={"fmc-input"}
                value={msrpFormatter.format(selectedMax)}
                data-testid={"max-range-filter"}
                onChange={handleMaxInputChange}
                onBlur={(e) => {
                  setSelectedMax(
                    Math.min(
                      Number(e.target.value.replace(/[^0-9.-]+/g, "")),
                      maxRange
                    )
                  );
                }}
              />
            </div>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px",
            }}>
            <Slider
              getAriaLabel={() => "Minimum distance"}
              min={minRange}
              max={maxRange}
              value={[selectedMin, selectedMax]}
              onChange={handleChange}
              disableSwap
              slotProps={{
                root: {className: "root"},
                rail: {className: styles.rangeRail},
                track: {className: styles.rangeTrack},
                thumb: {className: styles.rangeThumb},
                mark: {className: styles.rangeMark},
              }}
              marks={[
                {
                  value: minRange,
                  label: "Min",
                },
                {
                  value: maxRange,
                  label: "Max",
                },
              ]}
            />
          </Box>
        </div>
      )}
    </div>
  );
}
