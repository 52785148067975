import {loadVehicles} from "../../service/ModelEUploadService";
import {AdminGuard} from "../../components/admin/AdminGuard";

function ModelELoadVehicles() {
  async function loadF150s() {
    return await loadVehicles("WANAB-TFD-2024-F-150");
  }
  return (
    <div>
      <br></br>
      <h1>Dev Page</h1>
      <br></br>
      <h2>Model E</h2>
      <br></br>
      <button className={"fmc-button"} onClick={loadF150s}>
        Trigger F-150 Lightning Load
      </button>
    </div>
  );
}

export function DevPage() {
  return (
    <AdminGuard>
      <ModelELoadVehicles />
    </AdminGuard>
  );
}
