import React, {useEffect, useState} from "react";
import {RRCConfigurationGrid} from "./RRCConfigurationGrid";

import {RRC_Filters} from "../../components/modelE/Filters";
import {DropDownFilter} from "../../components/orderGridFilters/DropDownFilter";
import xIcon from "../../images/x-icon-blue.png";
import {useGlobalState} from "../../state/GlobalState";
import {DropdownOption} from "../../types/filter-option";
import {getContent} from "../../service/ContentService";
import {SubmitSelectionButton} from "../../components/modelE/SubmitSelectionButton";
import {atom, useRecoilState} from "recoil";
import {LoadingModal} from "../../components/modelE/LoadingModal";
import {
  getCurrentRrcConfigurations,
  submitNewAge,
} from "../../service/RRCConfigurationService";
import {ConfigurationFeature} from "../../models/Configuration";
import {AdminGuard} from "../../components/admin/AdminGuard";
import {isEnabled} from "../../components/modelE/Utils";

export interface EnumeratedRrcVehicleConfiguration {
  _id: string;
  age: string;
  rrc: string;
  vehicleLine: string;
  body: ConfigurationFeature;
  paint: ConfigurationFeature;
  trim: ConfigurationFeature;
  packages: ConfigurationFeature[];
  lastEditedDateTime: string;
  lastEditedBy: string;
}

export type ConfigurationsPerRRC = {
  key: string;
  age: number;
  rrc: string;
  vehicleLine: string;
  body: ConfigurationFeature;
  paint: ConfigurationFeature;
  trim: ConfigurationFeature;
  options: ConfigurationFeature[];
};

const replenishmentAgeConfigurationSelections = atom<ConfigurationsPerRRC[]>({
  key: "replenishmentAgeConfigurationSelections",
  default: [],
});

export {replenishmentAgeConfigurationSelections};

export function enumeratedRrcConfigurationToRrcConfigurationGridData(
  enumeratedRrcVehicleConfiguration: EnumeratedRrcVehicleConfiguration[]
): ConfigurationsPerRRC[] {
  return enumeratedRrcVehicleConfiguration
    .slice()
    .sort((a, b) => Number(a.age) - Number(b.age))
    .map((configuration) => {
      return {
        key: configuration._id,
        age: Number(configuration.age),
        rrc: RRCCodeToName(configuration.rrc),
        vehicleLine: configuration.vehicleLine,
        body: configuration.body,
        paint: configuration.paint,
        trim: configuration.trim,
        options: configuration.packages,
      };
    });
}

const nameArray = [
  {
    code: "85751",
    name: "Atlanta - F85751",
  },
  {
    code: "85752",
    name: "Chicago - F85752",
  },
  {
    code: "85753",
    name: "Dallas-Ford Worth - F85753",
  },
  {
    code: "85754",
    name: "Denver - F85754",
  },
  {
    code: "85750",
    name: "Flat Rock - F85750",
  },
  {
    code: "85755",
    name: "Fredericksburg - F85755",
  },
  {
    code: "85756",
    name: "Houston - F85756",
  },
  {
    code: "85757",
    name: "Kansas City - F85757",
  },
  {
    code: "85759",
    name: "Minneapolis - F85759",
  },
  {
    code: "85763",
    name: "New England - F85763",
  },
  {
    code: "85764",
    name: "New York - F85764",
  },
  {
    code: "85766",
    name: "Orlando - F85766",
  },
  {
    code: "85767",
    name: "Palm Beach - F85767",
  },
  {
    code: "85769",
    name: "Phoenix - F85769",
  },
  {
    code: "85770",
    name: "Pittsburgh - F85770",
  },
  {
    code: "85771",
    name: "Portland - F85771",
  },
  {
    code: "85772",
    name: "San Antonio - F85772",
  },
  {
    code: "85773",
    name: "San Diego - F85773",
  },
  {
    code: "85774",
    name: "San Francisco Bay - F85774",
  },
  {
    code: "85778",
    name: "Statesville - F85778",
  },
  {
    code: "85780",
    name: "Morgantown - F85780",
  },
];

export function RRCCodeToName(RRCCode: string): string {
  let filteredElement = Array.from(nameArray).filter(
    (value) => value.code === RRCCode
  )[0];
  return filteredElement ? filteredElement.name : RRCCode;
}

export function ReplenishmentAge() {
  const [selectedFilters, setSelectedFilters] = useGlobalState(
    "filterDropDownOptions"
  );
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [newAge, setNewAge] = useState(15);
  const [submitModalVisibility, setSubmitSubmitModalVisibility] =
    useState(false);
  const [selections] = useRecoilState(replenishmentAgeConfigurationSelections);
  const [inputActive, setInputActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [replenishmentAgeGridData, setReplenishmentAgeGridData] = useState<
    ConfigurationsPerRRC[]
  >([]);
  const [mostRecentlyEditedBy, setMostRecentlyEditedBy] = useState("");
  const [mostRecentlyEditedDateTime, setMostRecentlyEditedDateTime] =
    useState("");

  function findMostRecentlyEditedConfig(
    rrcVehicleConfigurations: EnumeratedRrcVehicleConfiguration[]
  ) {
    return rrcVehicleConfigurations.reduce((a, b) =>
      a.lastEditedDateTime > b.lastEditedDateTime ? a : b
    );
  }

  function updateConfigurationData() {
    setLoading(true);
    getCurrentRrcConfigurations()
      .then((response: EnumeratedRrcVehicleConfiguration[]) => {
        setReplenishmentAgeGridData(
          enumeratedRrcConfigurationToRrcConfigurationGridData(response)
        );
        let configWithMostRecentChange = findMostRecentlyEditedConfig(response);
        setMostRecentlyEditedBy(configWithMostRecentChange.lastEditedBy);
        setMostRecentlyEditedDateTime(
          configWithMostRecentChange.lastEditedDateTime
        );
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    updateConfigurationData();
    setShowClearFilter(false);
    setSelectedFilters(new Map());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [selections]);
  let [buttonIsEnabled, setButtonIsEnabled] = useState(false);

  useEffect(() => {
    setButtonIsEnabled(isEnabled(selections, loading));
  }, [selections, loading]);

  function handleClearFilters() {
    setSelectedFilters(new Map());
    setShowClearFilter(false);
  }

  function handleFilterChange(
    filterId: string,
    filterValue: DropdownOption | undefined
  ) {
    if (!filterValue) {
      return;
    }
    setSelectedFilters((previousState: Map<string, DropdownOption>) => {
      const newMap = new Map(previousState);
      newMap.set(filterId, filterValue);
      setShowClearFilter(true);
      return newMap;
    });
  }

  function handleNewAgeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(event.target.value);
    setNewAge(Number.isNaN(value) ? 0 : value);
    setInputActive(true); // Set input as active when the user types
  }

  return (
    <>
      {loading && <LoadingModal />}
      <AdminGuard>
        <div>
          <div>
            {submitModalVisibility && (
              <div
                className="fmc-dialog fds-align--center fmc-dialog--opened"
                role="dialog"
                aria-modal="true"
                data-testid="submitConfirmationModal"
                aria-label="Confirm submission modal">
                <div className="fmc-dialog__content">
                  <button
                    onClick={() => setSubmitSubmitModalVisibility(false)}
                    data-testid={"close"}
                    className="fmc-dialog__close fds-icon fds-font--ford-icons__clear"
                    id="close-confirm-submission-modal"
                  />

                  <h3 className="submit-age-modal-header-text">Success!</h3>
                  <p className="fmc-type--body1 fmc-mb-3 submit-age-modal-body-text">
                    Your stock replenishment age threshold changes have been
                    successfully applied.
                  </p>
                  <p className="fmc-type--body1 fmc-mb-3 submit-age-modal-body-text">
                    <strong>
                      {" "}
                      {selections.length}{" "}
                      {selections.length === 1 ? "record" : "records"}{" "}
                    </strong>
                    {selections.length === 1 ? "has" : "have"} been updated.
                  </p>
                  <button
                    onClick={() => {
                      setSubmitSubmitModalVisibility(false);
                      window.location.reload();
                    }}
                    data-testid={"close-button"}
                    className="fmc-button fmc-mb-3"
                    aria-label="close-button"
                    id={"close-button"}>
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
          <div>
            <div className={"portal-header"}>
              <div className={"title-and-tooltip"}>
                <h1 className="portal-name">
                  {getContent("ageThresholdTitle")}
                </h1>
                <label className={"portal-sub-header"}>
                  {getContent("ageSubHeaderText")}
                </label>
              </div>
            </div>
            <div className="filter-row">
              <div
                className="filter-container"
                data-testid={"filter-container"}>
                {RRC_Filters.map((filter) => {
                  return (
                    <DropDownFilter
                      key={filter + selectedFilters.get(filter)?.value}
                      dataGridColumnId={filter}
                      rrcConfigurationData={replenishmentAgeGridData}
                      onSelection={handleFilterChange}
                      selectedFilters={selectedFilters}
                    />
                  );
                })}
                <div className={"clear-filter-holder"}>
                  {showClearFilter && (
                    <>
                      <button
                        onClick={handleClearFilters}
                        data-testid="clear-filters"
                        className={"clearFilters-Mechanism"}
                        aria-label={"Clear Filters Mechanism"}>
                        <img src={xIcon} alt={"x"} className="xIcon" />
                        Clear filters
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className={"age-config-container"}>
              <label
                className={"age-config-text"}
                htmlFor="age-threshold-input">
                Set age for selected configurations to:{" "}
              </label>
              <input
                className={`css-13cymwt-control responsive-input ${
                  inputActive ? "input-used" : ""
                }`}
                type="text"
                id="age-threshold-input"
                aria-label="age-threshold-input"
                inputMode="numeric"
                pattern="[0-9]*"
                value={newAge}
                onChange={handleNewAgeChange}
                onBlur={() => inputActive || setInputActive(true)}
              />
              <label className={"age-config-text age-config-text-right"}>
                {" "}
                days{" "}
              </label>
              <div className={"age-config-text"}>
                <SubmitSelectionButton
                  enabled={buttonIsEnabled}
                  onSubmitClick={() => {
                    submitNewAge(selections, newAge.toString()).then(() =>
                      setSubmitSubmitModalVisibility(true)
                    );
                  }}
                  buttonText={"Submit"}
                />
              </div>
            </div>

            <RRCConfigurationGrid
              replenishmentAgeGridData={replenishmentAgeGridData}
              newAge={newAge}
              lastEditedBy={mostRecentlyEditedBy}
              lastEditedDateTime={mostRecentlyEditedDateTime}
            />
          </div>
        </div>
      </AdminGuard>
    </>
  );
}
