import {useGlobalState} from "../../state/GlobalState";

export function AdminGuard({
  children,
}: {
  children?: string | React.JSX.Element | (string | React.JSX.Element)[];
}) {
  const [isAdmin] = useGlobalState("hasAdminPermissions");
  if (isAdmin) {
    return <>{children}</>;
  }
  return <>403 FORBIDDEN</>;
}
