import {SalesCodeSelector} from "../../components/orderGridFilters/SalesCodeSelector";
import {VehicleLineSelector} from "../../components/orderGridFilters/VehicleLineSelector";
import {ConfigurationGrid} from "../../components/modelE/ConfigurationGrid";
import React, {useCallback, useEffect, useState} from "react";
import {StockGuide} from "../../components/modelE/stockGuide/StockGuide";
import "../../styles/ModelE.scss";
import {DropDownFilter} from "../../components/orderGridFilters/DropDownFilter";
import {ConfigurationGridData} from "../../types/ConfigurationGridData";
import {
  DealerConfigurationRequest,
  getCurrentModelEConfigurations,
  submitDealerConfigurationRequestByPurchaseList,
} from "../../service/ConfigurationService";
import {useGlobalState} from "../../state/GlobalState";
import {PortalTitle} from "../../components/home/PortalTitle";
import {ErrorModal} from "../../components/errorModal/ErrorModal";
import {DropdownOption} from "../../types/filter-option";
import {SubmitModal} from "../../components/vehicleSubmission/SubmitModal";
import {useRecoilState} from "recoil";
import {
  ConfigurationSelection,
  selectionState,
} from "../../state/SelectionState";
import {SubmitSelectionButton} from "../../components/modelE/SubmitSelectionButton";
import xIcon from "../../images/x-icon-blue.png";
import {Filters} from "../../components/modelE/Filters";
import {
  enumeratedConfigurationToConfigurationGridData,
  EnumeratedVehicleConfiguration,
} from "../../models/Configuration";
import {LoadingModal} from "../../components/modelE/LoadingModal";
import {WarningMessage} from "../../components/warningMessage/WarningMessage";
import {SuccessfulRequestModal} from "./SuccessfulRequestModal";
import {
  getStockingGuide,
  StockingGuide,
} from "../../service/StockingGuideService";
import {ModelEMenu} from "../../components/modelE/ModelEMenu";
import {Dealer} from "../../service/DealerService";
import RangeFilter from "../../components/orderGridFilters/RangeFilter";
import {isEnabled} from "../../components/modelE/Utils";

export function ModelEContainer() {
  const [selections] = useRecoilState<ConfigurationSelection[]>(selectionState);
  const [vehicleLine] = useGlobalState("vehicleLine");
  const [selectedFilters, setSelectedFilters] = useGlobalState(
    "filterDropDownOptions"
  );
  const [salesCode, setSalesCode] = useState("");
  const [dealerInformation, setDealerInformation] = useState<
    Dealer | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [userInputEmail, setUserInputEmail] = useState<string | null>(null);
  const [configurationData, setConfigurationData] = useState<
    ConfigurationGridData[]
  >([]);
  const [submitModalVisibility, setSubmitSubmitModalVisibility] =
    useState(false);
  const [successfulRequestModalOpen, setSuccessfulRequestModalOpen] =
    useState(false);
  const [stockingGuide, setStockingGuide] = useState<StockingGuide>({
    salesCode: "",
    vehicleLine: "",
    numberOfVehiclesOnLot: 0,
    demoAllowance: 0,
    stockTarget: 0,
    balanceToGo: 0,
  });
  let [buttonIsEnabled, setButtonIsEnabled] = useState(false);

  useEffect(() => {
    setButtonIsEnabled(isEnabled(selections, loading));
  }, [selections, loading]);

  function updateConfigurationData() {
    setLoading(true);
    getCurrentModelEConfigurations(salesCode)
      .then((response: EnumeratedVehicleConfiguration[]) => {
        setConfigurationData(
          enumeratedConfigurationToConfigurationGridData(response)
        );
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (salesCode.length > 0) {
      updateConfigurationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesCode]);

  useEffect(() => {
    setShowClearFilter(false);
    setSelectedFilters(new Map());
  }, [vehicleLine, setSelectedFilters]);

  useEffect(() => {
    if (vehicleLine === "" || salesCode === "") {
      return;
    }

    setLoading(true);
    getStockingGuide(salesCode, vehicleLine)
      .then((response) => {
        setStockingGuide({
          salesCode: salesCode,
          vehicleLine: vehicleLine,
          numberOfVehiclesOnLot: response.numberOfVehiclesOnLot,
          demoAllowance: response.demoAllowance,
          stockTarget: response.stockTarget,
          balanceToGo: response.balanceToGo,
        });
      })
      .finally(() => setLoading(false));
  }, [vehicleLine, salesCode, configurationData]);

  function handleSalesCodeChange(event: any) {
    setSalesCode(event.value);
  }

  const handleDealerInfoChange = useCallback((dealer: Dealer) => {
    setDealerInformation(dealer);
  }, []);

  function handleClearFilters() {
    setSelectedFilters(new Map());
    setShowClearFilter(false);
  }

  function handleFilterChange(
    filterId: string,
    filterValue: DropdownOption | undefined
  ) {
    if (!filterValue) {
      return;
    }
    setSelectedFilters((previousState: Map<string, DropdownOption>) => {
      const newMap = new Map(previousState);
      newMap.set(filterId, filterValue);
      setShowClearFilter(true);
      return newMap;
    });
  }

  async function processOrderRequest() {
    setLoading(true);
    setSubmitSubmitModalVisibility(false);
    let dealerConfigurationRequests: DealerConfigurationRequest[] =
      Object.entries(selections)
        .filter(([, selection]) => selection.selected)
        .map(([, selection]) => {
          return {
            amountRequested: selection.amount,
            configurationId: selection.configurationId,
            vehicleLine: selection.vehicleLine,
          };
        });
    await submitDealerConfigurationRequestByPurchaseList(
      salesCode,
      dealerConfigurationRequests,
      userInputEmail
    )
      .then((response) => {
        if (response.status === 201) {
          setSuccessfulRequestModalOpen(true);
          updateConfigurationData();
        }
      })
      .finally(() => setLoading(false));
  }

  return (
    <>
      {loading && <LoadingModal />}
      {successfulRequestModalOpen && (
        <SuccessfulRequestModal
          onClick={() => setSuccessfulRequestModalOpen(false)}
        />
      )}
      <PortalTitle />
      <div className="order-thing">
        <div className="timer-sales-code">
          <div className="dropdowns-container">
            <SalesCodeSelector
              salesCode={salesCode}
              salesCodeChangeEventHandler={handleSalesCodeChange}
              dealerInformationChangeHandler={handleDealerInfoChange}
            />
            <VehicleLineSelector salesCode={salesCode} />
            <ModelEMenu dealer={dealerInformation} />
          </div>
          <div className="timer-offering-status">
            <StockGuide stockingGuide={stockingGuide} />
          </div>
        </div>
        <WarningMessage />
        <div className="filter-row">
          <div className="filter-container">
            {Filters.map((filter) => {
              return (
                <DropDownFilter
                  key={filter + selectedFilters.get(filter)?.value}
                  dataGridColumnId={filter}
                  configurationData={configurationData.filter(
                    (config) => config.vehicleLine === vehicleLine
                  )}
                  onSelection={handleFilterChange}
                  selectedFilters={selectedFilters}
                />
              );
            })}
            {
              <RangeFilter
                configurationGridData={configurationData}
                setShowClearFilterButton={setShowClearFilter}
                showClearFilter={showClearFilter}
              />
            }
            <div className={"clear-filter-holder"}>
              {showClearFilter && (
                <>
                  <button
                    onClick={handleClearFilters}
                    data-testid="clear-filters"
                    className={"clearFilters-Mechanism"}
                    aria-label={"Clear Filters Mechanism"}>
                    <img src={xIcon} alt={"x"} className="xIcon" />
                    Clear filters
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {submitModalVisibility && (
          <SubmitModal
            setModalVisibilityFalse={() =>
              setSubmitSubmitModalVisibility(false)
            }
            setUserInputEmail={setUserInputEmail}
            userInputEmail={userInputEmail}
            confirmSubmission={processOrderRequest}
          />
        )}
        <ConfigurationGrid
          configurationData={configurationData}
          btg={stockingGuide.balanceToGo}
        />
        <p className={"selections-message"}>
          * Your selections will not be saved unless you click the{" "}
          <b>Submit Selection</b> button. Unsaved changes will be lost.
        </p>
        <div className={"bottom-submit-and-contact"}>
          <div className={"submitButton"}>
            <SubmitSelectionButton
              enabled={buttonIsEnabled}
              onSubmitClick={() => {
                setSubmitSubmitModalVisibility(true);
              }}
              buttonText={"Submit Selection"}
            />
          </div>
        </div>
      </div>
      <ErrorModal />
    </>
  );
}
