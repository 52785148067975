import * as React from "react";
import modeleLogo from "../../images/Logos/modeleLogo.png";
import "../../styles/Header.scss";
import {FordDefaultHeader} from "./Header";

export const ModelEHeader = () => {
  return <FordDefaultHeader logo={<ModelELogo />} />;
};

function ModelELogo() {
  return <img className={"header-logo"} src={modeleLogo} alt={"Modele logo"} />;
}
