import {Route, Routes} from "react-router-dom";
import {Home} from "../pages/home/Home";
import {Login} from "../pages/login/Login";
import {OauthCallback} from "../components/login/OauthCallback";
import {OrderHistory} from "../pages/orderHIstory/OrderHistory";
import {FAQ} from "../pages/faq/FAQ";
import {AdminPage} from "../pages/admin/AdminPage";
import React from "react";
import {PageNotFound} from "../pages/PageNotFound";
import {ReplenishmentAge} from "../pages/replenishmentAge/ReplenishmentAge";
import {DevPage} from "../pages/dev/DevPage";
import {useGlobalState} from "../state/GlobalState";

function Maintenance() {
  return (
    <div style={{padding: "3rem"}}>
      <h2>Model E Wholesale Maintenance</h2>
      <div
        className={"warning-message-inner-container"}
        style={{padding: "3rem"}}>
        <div className="warning-message-text">
          The EV-Dealer Stocking Portal is undergoing routine maintenance today,
          Sunday, October 27, between 8am and Noon EST. The system is currently
          unavailable. We apologize for the inconvenience. We will be return the
          system to operational status as quickly as possible
        </div>
      </div>
    </div>
  );
}

const pages = {
  home: <Home />,
  history: <OrderHistory />,
  faq: <FAQ />,
  replenishmentAge: <ReplenishmentAge />,
};

const maintenancePages = {
  home: <Maintenance />,
  history: <Maintenance />,
  faq: <Maintenance />,
  replenishmentAge: <Maintenance />,
};

export function ModelEOfferingRoutes() {
  const [featureFlags] = useGlobalState("featureFlags");
  const pagesToRender = featureFlags.includes("maintenanceEnabled")
    ? maintenancePages
    : pages;

  return (
    <>
      <Routes>
        <Route path="/" element={pagesToRender.home} />
        <Route path="/login" element={<Login />} />
        <Route path="/oauth-callback" element={<OauthCallback />} />
        <Route path="/history" element={pagesToRender.history} />
        <Route
          path="/replenishment-age"
          element={pagesToRender.replenishmentAge}
        />
        <Route path="/faq" element={pagesToRender.faq} />
        <Route path="/dev" element={<DevPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}
