import {useGlobalState} from "../../state/GlobalState";
import React, {useEffect, useState} from "react";
import {FormattedOrder} from "../../types/order";

export function OfferingReview() {
  const [currentLanguage] = useGlobalState("language");
  const [data] = useGlobalState("orderGridData");
  const [totalUnselectedVehicles, setTotalUnselectedVehicles] = useState(0);

  useEffect(() => {
    setUnselectedVehicles();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  function setUnselectedVehicles() {
    let selectedVehicles = 0;

    data.forEach((order: FormattedOrder) => {
      if (order.formattedSelection) selectedVehicles++;
    });

    setTotalUnselectedVehicles(data.length - selectedVehicles);
  }

  return getOfferingData(currentLanguage, data, totalUnselectedVehicles);
}

function getOfferingData(
  language: string,
  data: FormattedOrder[],
  totalUnselectedVehicles: number
) {
  if (language === "fr") {
    return (
      <div className={"offering-status-body"}>
        <div>
          ll reste <b>{data.length}</b>
        </div>
        <div> offre(s) de</div>
        <div>{totalUnselectedVehicles} à examiner</div>
      </div>
    );
  } else if (language === "es") {
    return (
      <div className={"offering-status-body"}>
        <div className={"vehicle-response-count"}>
          {totalUnselectedVehicles}
        </div>
        <sup>de {data.length}</sup>
        <div>vehículos pendientes de revisión</div>
      </div>
    );
  } else {
    return (
      <div className={"offering-status-body"}>
        <div className={"vehicle-response-count"}>
          {totalUnselectedVehicles}
        </div>
        <sup>of {data.length}</sup>
        <div>vehicles left to review</div>
      </div>
    );
  }
}
