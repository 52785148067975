import {getHttpClient} from "./API";

export interface Dealer {
  salesCode: string;
  dealerName: string;
  dealerWithZone: string;
  region: string;
  zone: string;
  brand: string;
  hasHadAllocations: boolean;
  rrc: RRC[];
  paCode: PaCode;
  isECommerceEnrolled: boolean;
}

export interface RRC {
  rrc: string;
}

export interface PaCode {
  paCode: string;
}

export interface DealerInventoryMetrics {
  vehicleLine: string;
  count: number;
}

export async function getDealerInformation(salesCode: string): Promise<Dealer> {
  const response = await getHttpClient().get("/api/dealer/" + salesCode);
  return response.data;
}

export const getDealerInventoryMetrics = async (
  salesCode: string
): Promise<DealerInventoryMetrics[]> => {
  const response = await getHttpClient().get(
    `/api/dealerInventoryMetrics/${salesCode}`
  );
  return response.data;
};
