import * as React from "react";
import {useState} from "react";
import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {logout} from "../../pages/login/Login";
import {ContactUsButton} from "./buttons/ContactUsButton";
import {SVRMenuButton} from "./buttons/SVRMenuButton";
import {OrderHistoryMenuButton} from "./buttons/OrderHistoryMenuButton";
import {ContactUsModal} from "../contactUs/ContactUsModal";
import {WarningMessageMenuItem} from "./buttons/WarningMessageMenuItem";
import {WarningMessageModal} from "../warningMessage/WarningMessageModal";
import {FAQMenuButton} from "./buttons/FAQMenuButton";
import {getIcon, IconName} from "../../service/iconFinderService";
import {AdminMenuButton} from "./buttons/AdminMenuButton";
import {getGlobalState, useGlobalState} from "../../state/GlobalState";
import {getSkin, Skin} from "../../service/SkinService";
import {getContent} from "../../service/ContentService";
import {ReplenishmentAgeMenuButton} from "./buttons/ReplenishmentAgeMenuButton";

export function Navigation() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [contactUsModalVisible, setContactUsModalVisible] = useState(false);
  const [warningMessageModalVisible, setWarningMessageModalVisible] =
    useState(false);
  const [userDropdownStyleClass, setUserDropdownStyleClass] =
    useState("userDropdown");
  const [currentLanguage] = useGlobalState("language");

  const skin = getSkin();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuOpen(true);
    setAnchorElement(event.currentTarget);
    if (skin === Skin.LPIVW) {
      setUserDropdownStyleClass("userDropdown lincolnBorder");
    }
  }

  function handleClose() {
    setMenuOpen(false);
    setAnchorElement(null);
    if (skin === Skin.LPIVW) {
      setUserDropdownStyleClass("userDropdown");
    }
  }

  function LogoutButton() {
    return (
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <img
            src={getIcon(IconName.Logout)}
            className="menu-button-icons"
            alt={"contact us menu button"}
          />
        </ListItemIcon>
        <ListItemText>
          {getContent("logoutMenuLabel", currentLanguage)}
        </ListItemText>
      </MenuItem>
    );
  }

  function ModalComponents() {
    return (
      <>
        {contactUsModalVisible && (
          <ContactUsModal
            isVisible={contactUsModalVisible}
            setVisibility={setContactUsModalVisible}
          />
        )}
        {warningMessageModalVisible && (
          <WarningMessageModal
            isVisible={warningMessageModalVisible}
            setVisibility={setWarningMessageModalVisible}
          />
        )}
      </>
    );
  }

  function MenuOptions() {
    return (
      <>
        {getSkin() !== Skin.MEW && <SVRMenuButton />}
        <OrderHistoryMenuButton data-testid={"order-history-menu-button"} />
        <FAQMenuButton />
        <ContactUsButton setContactUsVisible={setContactUsModalVisible} />
        <WarningMessageMenuItem
          setWarningMessageVisible={setWarningMessageModalVisible}
        />
        <AdminMenuButton show={getGlobalState("hasAdminPermissions")} />
        <ReplenishmentAgeMenuButton
          show={getSkin() === Skin.MEW && getGlobalState("hasAdminPermissions")}
        />
        <LogoutButton />
      </>
    );
  }

  return (
    <>
      <ModalComponents />
      <div className={userDropdownStyleClass}>
        <button
          onClick={handleClick}
          data-testid={"userDropDownButton"}
          id={"menu-dropdown-button"}>
          <span className={"menu-icon"}>
            Menu
            <img
              data-testid={"menuIcon"}
              src={getIcon(IconName.MenuIcon)}
              alt={"Menu Drop Down"}
              className={"menu-icon-spacing"}
            />
          </span>
        </button>
        <Menu
          data-testid={"menu"}
          open={menuOpen}
          anchorEl={anchorElement}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{horizontal: "right", vertical: "top"}}
          anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
          <MenuOptions />
        </Menu>
      </div>
    </>
  );
}
