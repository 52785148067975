import React, {useEffect, useState} from "react";
import {Table} from "antd";
import {TableColumn} from "../../models/TableColumn";
import {getOrderHistory} from "../../service/ModelEOrderService";
import {SalesCodeSelector} from "../orderGridFilters/SalesCodeSelector";
import "../../styles/OrderHistoryList.scss";
import {VehicleConfigurationOrder} from "../../models/Configuration";
import {ModelEConfigurationStatus} from "../../enums/ModelEConfigurationStatus";

interface ModelEOrderStatus {
  key: string;
  vin: string;
  vehicleConfiguration: VehicleConfigurationOrder;
  requestedDate: string;
  selection: ModelEConfigurationStatus;
}

function getRequestedDate(it: ModelEOrderStatus) {
  if (it.requestedDate) {
    return new Date(it.requestedDate).toLocaleDateString();
  }
}

export function OrderHistoryList() {
  const [orderHistory, setOrderHistory] = useState([]);
  const [salesCode, setSalesCode] = useState("");

  function handleSalesCodeChange(event: any) {
    setSalesCode(event.value);
  }

  function getStatus(it: ModelEOrderStatus) {
    switch (it.selection) {
      case ModelEConfigurationStatus.ON_HOLD:
        return "Requested";
      case ModelEConfigurationStatus.ACCEPTED:
        return "Accepted";
      case ModelEConfigurationStatus.REJECTED:
        return "Rejected";
      default:
        return it.selection;
    }
  }

  useEffect(() => {
    getOrderHistory(salesCode).then((rawOrderHistory) => {
      const orderHistory = rawOrderHistory.map((it: ModelEOrderStatus) => {
        return {
          key: it.key,
          vin: it.vin,
          vehicleLine:
            it.vehicleConfiguration.vehicleLine === "F-150"
              ? "Lightning"
              : it.vehicleConfiguration.vehicleLine,
          body: it.vehicleConfiguration.body.description,
          modelYear: it.vehicleConfiguration.modelYear,
          trim: it.vehicleConfiguration.trim.description,
          paint: it.vehicleConfiguration.paint.description,
          status: getStatus(it),
          requestedDate: getRequestedDate(it),
        };
      });
      setOrderHistory(orderHistory);
    });
  }, [salesCode]);

  const columns = [
    new TableColumn({
      title: "Request Date",
      key: "requestedDate",
    }),
    new TableColumn({
      title: "VIN",
      key: "vin",
    }),
    new TableColumn({
      title: "Vehicle Line",
      key: "vehicleLine",
    }),
    new TableColumn({
      title: "Model Year",
      key: "modelYear",
    }),
    new TableColumn({
      title: "Body",
      key: "body",
    }),
    new TableColumn({
      title: "Paint",
      key: "paint",
    }),
    new TableColumn({
      title: "Status",
      key: "status",
    }),
  ];

  return (
    <>
      <div className={"sales-code-container-margin"}>
        <SalesCodeSelector
          salesCode={salesCode}
          salesCodeChangeEventHandler={handleSalesCodeChange}
        />
      </div>

      <Table
        className={"rr-table rr-table--alternating-rows order-history-width"}
        columns={columns}
        dataSource={orderHistory}
        pagination={false}
      />
    </>
  );
}
