import {
  ConfigurationFeature,
  EnumeratedVehicleConfiguration,
} from "../models/Configuration";
import {ModelEConfigurationStatus} from "../enums/ModelEConfigurationStatus";

export type ConfigurationGridData = {
  configurationId: string;
  body: ConfigurationFeature;
  code: ConfigurationFeature;
  trim: ConfigurationFeature;
  paint: ConfigurationFeature;
  count: number;
  vehicleLine: string;
  packages: ConfigurationFeature[];
  options: ConfigurationFeature[];
  dealerInstalledOptions: ConfigurationFeature[];
  selection: ModelEConfigurationStatus;
  msrp: number;
  msrpWithDetails: MSRPWithDetails;
  engine: ConfigurationFeature;
  modelYear: string;
};

export function buildConfigurationGridData(
  configuration: EnumeratedVehicleConfiguration,
  count: number,
  selection: ModelEConfigurationStatus
): ConfigurationGridData {
  return {
    configurationId: configuration.vehicleConfigurationOrder.key,
    body: configuration.vehicleConfigurationOrder.body,
    code: configuration.vehicleConfigurationOrder.code,
    trim: configuration.vehicleConfigurationOrder.trim,
    paint: configuration.vehicleConfigurationOrder.paint,
    count: count,
    vehicleLine: configuration.vehicleConfigurationOrder.vehicleLine,
    packages: configuration.vehicleConfigurationOrder.packages,
    options: configuration.vehicleConfigurationOrder.options,
    dealerInstalledOptions:
      configuration.vehicleConfigurationOrder.dealerInstalledOptions,
    selection: selection,
    msrp: configuration.vehicleConfigurationOrder.msrp,
    msrpWithDetails: configuration.vehicleConfigurationOrder.msrpWithDetails,
    engine: configuration.vehicleConfigurationOrder.engine,
    modelYear: configuration.vehicleConfigurationOrder.modelYear,
  };
}

export type MSRPWithDetails = {
  totalMSRPWithDestinationDelivery: number;
  baseMSRP: number;
  onlyOptionsMSRP: number;
  destinationDeliveryPrice: number;
};

export function getRowKey(it: ConfigurationGridData): string {
  return it.configurationId + it.selection;
}

function getTopBarFeatures(configurationGridData: ConfigurationGridData) {
  return [
    configurationGridData.code,
    configurationGridData.body,
    configurationGridData.trim,
    configurationGridData.engine,
    configurationGridData.paint,
  ];
}

export function getTopBarFeaturesWithMsrp(
  configurationGridData: ConfigurationGridData
): ConfigurationFeature[] {
  return getTopBarFeatures(configurationGridData).filter((it) => it.msrp);
}
