import React, {LegacyRef, useEffect, useRef, useState} from "react";
import {getContent} from "../../service/ContentService";
import {Table} from "antd";
import {TableColumn} from "../../models/TableColumn";
import {CSVLink} from "react-csv";
import "../../styles/Table.scss";
import {
  retrieveAllAvailableSalesCodesForAuthenticatedUser,
  SalesCodeType,
} from "../../service/SalesCodeService";
import {
  getCompletedOfferingsForDealer,
  Offering,
} from "../../service/OfferingService";
import Select from "react-select";
import {getOrderHistoryBySalesCodeAndOfferingId} from "../../service/ReportService";
import {getGlobalState, useGlobalState} from "../../state/GlobalState";
import {getCountryLocale} from "../modelE/Utils";

export function OrderHistoryBySalesCode() {
  const [salesCodes, setSalesCodes] = useState<SalesCodeType[]>([]);
  const [offerings, setOfferings] = useState([]);
  const [selectedSalesCode, setSelectedSalesCode] = useState({
    code: "",
    name: "",
  });
  const [selectedOffering, setSelectedOffering] = useState({
    id: "",
    dateRange: "",
  });
  const [downloadButtonEnabled, setDownloadButtonEnabled] = useState(false);
  const [currentLanguage] = useGlobalState("language");
  const [offeringDropDownButtonEnabled, setOfferingDropDownButtonEnabled] =
    useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [orderHistoryFileName, setOrderHistoryFileName] = useState("");
  const csvDownloadLink: LegacyRef<any> = useRef();
  const headers: {label: string; key: string}[] = [
    {
      label: getContent(
        "orderHistorySalesCodeLabel",
        getGlobalState("language")
      ),
      key: "salesCode",
    },
    {
      label: getContent("dealershipNameLabel", getGlobalState("language")),
      key: "dealershipName",
    },
    {
      label: getContent(
        "orderHistoryOfferingPeriodLabel",
        getGlobalState("language")
      ),
      key: "offeringPeriod",
    },
    {
      label: getContent("vehicleLineLabel", getGlobalState("language")),
      key: "vehicleLine",
    },
    {label: getContent("vinLabel", getGlobalState("language")), key: "vin"},
    {label: getContent("bodyLabel", getGlobalState("language")), key: "body"},
    {label: getContent("codeLabel", getGlobalState("language")), key: "code"},
    {
      label: getContent("engineLabel", getGlobalState("language")),
      key: "engine",
    },
    {label: getContent("paintLabel", getGlobalState("language")), key: "paint"},
    {label: getContent("trimLabel", getGlobalState("language")), key: "trim"},
    {
      label: getContent("statusLabel", getGlobalState("language")),
      key: "status",
    },
    {label: getContent("userLabel", getGlobalState("language")), key: "user"},
    {
      label: getContent("optionsLabel", getGlobalState("language")),
      key: "options",
    },
    {
      label: getContent("packagesLabel", getGlobalState("language")),
      key: "packages",
    },
  ];

  function getFormattedDate(offering: Offering) {
    return (
      new Date(offering.startTime).toLocaleString(
        [getCountryLocale(currentLanguage)],
        {
          month: "short",
          day: "numeric",
        }
      ) +
      " - " +
      new Date(offering.endTime).toLocaleString(
        [getCountryLocale(currentLanguage)],
        {
          month: "short",
          day: "numeric",
          year: "numeric",
        }
      )
    );
  }

  useEffect(() => {
    retrieveAllAvailableSalesCodesForAuthenticatedUser()
      .then((salesCodes) => {
        setSalesCodes(salesCodes);
      })
      .catch((error) => console.log(error.message));
  }, []);

  useEffect(() => {
    if (selectedSalesCode.code !== "") {
      getCompletedOfferingsForDealer(selectedSalesCode.code).then(
        (offeringData) => {
          setOfferings(
            offeringData.map((offering: Offering) => {
              return {label: getFormattedDate(offering), value: offering._id};
            })
          );
        }
      );
      setOfferingDropDownButtonEnabled(true);
    }
    // eslint-disable-next-line
  }, [selectedSalesCode]);

  const downloadButtonHandler = () => {
    getOrderHistoryBySalesCodeAndOfferingId(
      selectedSalesCode.code,
      selectedOffering.id
    ).then((response) => {
      setOrderHistoryData(response);
      setOrderHistoryFileName(
        getContent("orderHistoryOf", currentLanguage) +
          selectedSalesCode.name +
          getContent("orderHistoryFor", currentLanguage) +
          selectedOffering.dateRange +
          ".csv"
      );
      if (csvDownloadLink.current) csvDownloadLink.current.link.click();
    });
  };

  useEffect(() => {
    setDownloadButtonEnabled(
      selectedSalesCode.code !== "" && selectedOffering.id !== ""
    );
  }, [selectedSalesCode, selectedOffering]);

  return (
    <div data-testid={"order-history-by-sales-code"}>
      <Table
        dataSource={[{key: "testStuff"}]}
        className="rr-table rr-table--alternating-rows"
        pagination={false}
        columns={[
          new TableColumn({
            title: getContent("orderHistorySalesCodeLabel", currentLanguage),
            key: "salescode",
            render: () => (
              <Select
                aria-label={"SalesCode"}
                options={salesCodes}
                onChange={(event: any) =>
                  setSelectedSalesCode({code: event.value, name: event.label})
                }
                placeholder={getContent(
                  "salesCodePlaceholderText",
                  currentLanguage
                )}
              />
            ),
          }),
          new TableColumn({
            title: getContent(
              "orderHistoryOfferingPeriodLabel",
              currentLanguage
            ),
            key: "offeringPeriod",
            render: () => {
              return (
                <Select
                  aria-label={"Completed Offerings"}
                  options={offerings}
                  onChange={(event: any) =>
                    setSelectedOffering({
                      id: event.value,
                      dateRange: event.label,
                    })
                  }
                  placeholder={getContent(
                    "offeringPlaceholderText",
                    currentLanguage
                  )}
                  isDisabled={!offeringDropDownButtonEnabled}
                  noOptionsMessage={() =>
                    getContent("noPastOfferings", currentLanguage)
                  }
                />
              );
            },
          }),
          new TableColumn({
            title: getContent(
              "orderHistoryOfferingDownloadLabel",
              currentLanguage
            ),
            key: "download",
            render: () => {
              return (
                <div>
                  <button
                    className={"fmc-button"}
                    onClick={() => downloadButtonHandler()}
                    disabled={!downloadButtonEnabled}>
                    {getContent("orderHistoryDownloadButton", currentLanguage)}
                  </button>
                  <CSVLink
                    data-testid={"csv-download-link"}
                    headers={headers}
                    data={orderHistoryData}
                    filename={orderHistoryFileName}
                    ref={csvDownloadLink}
                    target="_blank"
                  />
                </div>
              );
            },
          }),
        ]}
      />
    </div>
  );
}
