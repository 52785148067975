import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";
import {getGlobalState, setGlobalState} from "../state/GlobalState";

export class FeatureFlagService {
  public static setFeatureFlags() {
    getHttpClient()
      .get(ApiUrls.featureFlags)
      .then((response) => {
        setGlobalState("featureFlags", response.data);
      });
  }

  public static getFeatureFlag(myFeatureFlag: string) {
    return getGlobalState("featureFlags").includes(myFeatureFlag);
  }
}
