import * as React from "react";
import fordLogo from "../../images/Logos/fordOval.png";
import {FordDefaultHeader} from "./Header";

export function FordHeader() {
  return <FordDefaultHeader logo={<FordLogo />} />;
}

function FordLogo() {
  return <img className={"header-logo"} src={fordLogo} alt={"Ford logo"} />;
}
