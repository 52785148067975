import {useNavigate} from "react-router-dom";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import {getIcon, IconName} from "../../../service/iconFinderService";
import * as React from "react";

export function ReplenishmentAgeMenuButton(props: {show: boolean}) {
  const navigation = useNavigate();
  if (props.show) {
    return (
      <>
        <MenuItem
          data-testid={"replenishment-age-menu-button"}
          onClick={() => {
            navigation("/replenishment-age");
          }}>
          <ListItemIcon>
            <img
              src={getIcon(IconName.Admin)}
              className="menu-button-icons"
              alt={"Replenishment Age Button"}
            />
          </ListItemIcon>
          <ListItemText>Replenishment Age</ListItemText>
        </MenuItem>
      </>
    );
  } else return <div></div>;
}
