function isFieldTrueAnywhere(obj: {
  [x: string]: {[x: string]: boolean};
  hasOwnProperty?: any;
}) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key]["selected"] || obj[key]["rrc"]) {
        return true;
      }
    }
  }
  return false;
}

export function isEnabled(selections: {}, loading: boolean) {
  return isFieldTrueAnywhere(selections) && !loading;
}

export function getCountryLocale(language: string) {
  switch (language) {
    case "es":
      return "es-MX";
    case "fr":
      return "fr-CA";
    default:
      return "en-US";
  }
}
