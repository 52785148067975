import {useState} from "react";
import Modal from "react-modal";
import {
  getCvwDisclaimerAccepted,
  putCvwDisclaimer,
} from "../../service/UserService";
import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function CvwDisclaimer() {
  const [currentLanguage] = useGlobalState("language");
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
  const [shouldShowCvwDisclaimer, setShouldShowCvwDisclaimer] = useState(false);

  let [isOpen, setIsOpen] = useState(true);

  useState(() => {
    getCvwDisclaimerAccepted().then((response) => {
      if (response.data === false) {
        setShouldShowCvwDisclaimer(true);
      }
    });
  });

  return (
    <>
      {shouldShowCvwDisclaimer && (
        <Modal isOpen={isOpen} ariaHideApp={false}>
          <h3 className={"modal-header"}>
            {getContent("title", currentLanguage)}
          </h3>
          <h3 className={"modal-header"}>
            {getContent("allocationMethodologyNoticeTitle", currentLanguage)}
          </h3>
          <div className={"modal-description"}>
            {getContent("modalDescriptionPartOne", currentLanguage)}
            <br />
            {getContent("modalDescriptionPartTwo", currentLanguage)}
          </div>
          <div className="fmc-mt-6 center-content">
            <input
              className={"fmc-mr-2"}
              onChange={() => {
                setIsCheckboxDisabled(!isCheckboxDisabled);
              }}
              type={"checkbox"}
            />
            {getContent("disclaimerAcceptanceLabel", currentLanguage)}
          </div>
          <div className="fmc-mt-6 center-content">
            <button
              className={"fmc-button fmc-mb-3"}
              disabled={isCheckboxDisabled}
              onClick={() => {
                putCvwDisclaimer().then(() => {
                  setIsOpen(false);
                });
              }}>
              {getContent("continueLabel", currentLanguage)}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
