import React from "react";

type SubmitSelectionButtonProps = {
  enabled: boolean;
  onSubmitClick: () => void;
  buttonText: string;
};

export function SubmitSelectionButton(props: SubmitSelectionButtonProps) {
  return (
    <button
      className={"fmc-button submit-button"}
      aria-label={"Submit Selection Button"}
      disabled={!props.enabled}
      onClick={props.onSubmitClick}>
      {props.buttonText}
    </button>
  );
}
