import {
  getSalesCodesByBrand,
  SalesCodeType,
} from "../../service/SalesCodeService";
import "react-dropdown/style.css";
import Select from "react-select";
import "../../styles/SalesCodeSelector.scss";
import {useEffect, useState} from "react";
import {Dealer, getDealerInformation} from "../../service/DealerService";
import {getSkin, Skin} from "../../service/SkinService";
import {getBrand} from "../../service/BrandService";
import {getContent} from "../../service/ContentService";
import {useGlobalState} from "../../state/GlobalState";

export function salesCodeDropDownMenu(
  salesCodeOptions: SalesCodeType[],
  handleChange: (event: any) => void,
  salesCode: string,
  currentLanguage: string
) {
  return (
    <Select
      className=" sales-code-dropdown"
      options={salesCodeOptions}
      aria-label={"sales code options"}
      name="salesCodesOptions"
      inputId="salesCodesOptions"
      onChange={handleChange}
      placeholder={getContent(
        "selectSalesCodePlaceholderText",
        currentLanguage
      )}
      value={
        salesCode
          ? {value: salesCode, label: salesCodeDemoMask(salesCode)}
          : null
      }
    />
  );
}

function salesCodeDemoMask(salesCode: string): string {
  if (
    (window.location.hostname.includes("evdealerstockingportal-preprod") ||
      window.location.hostname.includes("localhost")) &&
    salesCode === "F72733"
  ) {
    return "F00000";
  }
  return salesCode;
}

export const SalesCodeSelector = ({
  dealerInformationChangeHandler,
  salesCode,
  salesCodeChangeEventHandler,
}: {
  salesCode: string;
  salesCodeChangeEventHandler: (event: any) => void;
  dealerInformationChangeHandler?: (newDealerInformation: Dealer) => void;
}) => {
  const [salesCodeOptions, setSalesCodeOptions] = useState<SalesCodeType[]>([]);
  const [dealerName, setDealerName] = useState<string>("");
  const [currentLanguage] = useGlobalState("language");

  const skin = getSkin();

  useEffect(() => {
    getSalesCodesByBrand(getBrand(skin)).then((salesCodes) =>
      setSalesCodeOptions(
        window.location.hostname.includes("evdealerstockingportal-preprod") ||
          window.location.hostname.includes("localhost")
          ? salesCodes.map((it: SalesCodeType) => {
              if (it.value === "F72733") {
                return {value: it.value, label: "F00000"} as SalesCodeType;
              }
              return it;
            })
          : salesCodes
      )
    );
  }, [skin]);

  useEffect(() => {
    if (salesCode !== "") {
      getDealerInformation(salesCode).then((data: Dealer) => {
        setDealerName(data.dealerName);
        dealerInformationChangeHandler && dealerInformationChangeHandler(data);
      });
    }
  }, [salesCode, skin, dealerInformationChangeHandler]);

  const displayWarningMessage = () => {
    return salesCode === "";
  };

  return (
    <div
      className={"fmc-top-banner--warning salesCodeContainer"}
      data-testid={"sales-code-container"}>
      <div>
        <form data-testid={"form"} style={{minWidth: "250px"}}>
          <label className="hidden" htmlFor="salesCodesOptions">
            Sales Codes
          </label>
          {salesCodeDropDownMenu(
            salesCodeOptions,
            salesCodeChangeEventHandler,
            salesCode,
            currentLanguage
          )}
        </form>
      </div>
      {displayWarningMessage() ? (
        <div
          data-testid={"salesCodeSelectWarning"}
          className={"salesCodeSelectWarning"}>
          <div
            className={
              getSkin() === Skin.LPIVW ? "lincoln-warning-icon" : "warning-icon"
            }>
            <span
              className={
                getSkin() === Skin.LPIVW
                  ? "lincoln-warning-icon fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
                  : "warning-icon fds-icon fds-icon--22 fds-font--ford-icons__warning-filled"
              }></span>
            <span>
              &nbsp;{" "}
              {getContent("selectSalesCodeWarningMessage", currentLanguage)}{" "}
              &nbsp;
            </span>
          </div>
        </div>
      ) : (
        <p data-testid={"dealer-name"}>{dealerName}</p>
      )}
    </div>
  );
};
